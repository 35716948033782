import React, { Suspense } from 'react';
import { render } from 'react-snapshot';
import "./index.css";
// import "./js_composer.min.css";
// import "http://staging.latv.com/other/wp-content/plugins/js_composer/assets/css/js_composer.min.css";

import reportWebVitals from './reportWebVitals';
// import HttpsRedirect from 'react-https-redirect';
// import ReactDOM from 'react-dom';
// import 'bootstrap/dist/js/bootstrap.min.js'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'font-awesome/css/font-awesome.min.css'
// import Main from "./Main";
// import "./Fonts/Archivo-Regular.ttf";
// import "./Fonts/Archivo-Bold.ttf";
// import "./Fonts/HelveticaNeueLTStd-BlkEx.otf";
// import Font1 from "./Fonts/Roboto-Black.ttf";
// import Font2 from "./Fonts/Roboto-Regular.ttf";
// import Font3 from "./Fonts/Roboto-Bold.ttf";
// import Font4 from "./Fonts/Roboto-Medium.ttf";

const PreloadStyle = importStatement => {
  importStatement.preload = importStatement;
  const Component = importStatement;
  return Component;
};
const BootstrapJs = PreloadStyle(() => import("bootstrap/dist/js/bootstrap.min.js"));
BootstrapJs.preload();
const BootstrapCss = PreloadStyle(() => import("bootstrap/dist/css/bootstrap.min.css"));
BootstrapCss.preload();
const FontAwesome = PreloadStyle(() => import("font-awesome/css/font-awesome.min.css"));
FontAwesome.preload();
// const Font1 = PreloadStyle(() => import("./Fonts/Roboto-Black.ttf"));
// Font1.preload();
// const Font2 = PreloadStyle(() => import("./Fonts/Roboto-Regular.ttf"));
// Font2.preload();
// const Font3 = PreloadStyle(() => import("./Fonts/Roboto-Bold.ttf"));
// Font3.preload();
// const Font4 = PreloadStyle(() => import("./Fonts/Roboto-Medium.ttf"));
// Font4.preload();
const Font5 = PreloadStyle(() => import("./Fonts/Archivo-Regular.ttf"));
Font5.preload();
const Font6 = PreloadStyle(() => import("./Fonts/Archivo-Bold.ttf"));
Font6.preload();
const Font7 = PreloadStyle(() => import("./Fonts/HelveticaNeueLTStd-BlkEx.otf"));
Font7.preload();
const Font8 = PreloadStyle(() => import("./Fonts/DK-Die-Bruecke.woff"));
Font8.preload();
const Font9 = PreloadStyle(() => import("./Fonts/helveticaneue.woff"));
Font9.preload();
// const StyleMain = PreloadStyle(() => import("./index.css"));
// StyleMain.preload();
const ReactLazyPreload = importStatement => {
  const Component = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};
const Main = ReactLazyPreload(() => import("./Main"));
Main.preload();
// const StyleMain = ReactLazyPreload(() => import("./index.css"));
// StyleMain.preload();

// import Main from './Main';
// React.lazy(() => import('./index.css'));



// const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(<Suspense fallback={<div>&nbsp;</div>}><Main /> </Suspense>, rootElement);
// } else {
//   ReactDOM.render(<Suspense fallback={<div>&nbsp;</div>}><Main /> </Suspense>, rootElement);
// }
render(
  // <HttpsRedirect>
  // <React.StrictMode>
    <Suspense fallback={<div>&nbsp;</div>}>
      <Main />
    </Suspense>,
  // </React.StrictMode>,
  // </HttpsRedirect>,
  document.getElementById('root')
);
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();